.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.dividerDot {
  height: 2px;
  width: 2px;
  background-color: currentColor;
  margin: 0 8px;
}

.semi-bold {
  font-weight: 600;
}

.button-reset {
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.Twilio-RootContainer {
  font-family: Montserrat, Arial, sans-serif;
}

.Twilio-MessageBubble-UserName {
  font-size: 12px;
}

.Twilio-MessageInputArea-TextArea {
  padding-top: 2px;
}

.Twilio-MessageCanvasTray-default .MessageCanvasTrayContent h6 {
  font-size: 16px;
}

.Twilio-MessageCanvasTray-default p {
  width: 230px;
  text-align: center;
}

.Twilio-MessageCanvasTray-default button {
  border-radius: 3px;
}

.pending {
  position: relative;
}

.pending:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}
